/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2022-11-29 09:39:22
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-06 09:17:33
 */
import { ReqPage } from '@/model/common/reqPage'

/**
 * @description:
 * @param { string } id id
 * @param { string } priceNumber 分析表号
 * @param { string } targetCountry 目标国家
 * @param { string } productName 物料名称
 * @param { string } productCode 物料代码
 * @param { string } productCategory 物料类别
 * @param { string } modelCount 型号总数
 * @param { string } sampleCount 样本数
 * @param { string } monetaryUnit 货币单位
 * @param { string } intervalCount string 区间json （用于展示）
 * @param { string } fileId 文件id
 * @param { string } rate 兑率
 * @param { string } netProfit 净利润
 * @param { string } operationCost 运营成本
 * @param { string } logisticsCost 物流成本
 * @param { string } exportTariff 出口关税
 * @param { string } importTariff 进口关税
 * @param { string } maxDiscount 最大折扣
 * @param { string } profitAfterDiscount 折后利润
 * @param { string } optimalProportion 最优占比
 * @param { string } dominantProportion 优势占比
 * @param { string } nonDisadvantageousProportion 非劣势占比
 * @param { string } discountAfterProfit 折后利润折扣
 * @param { string } remark 备注
 * @param { string } isEntry 是否录入 0：未录入 1：已录入
 * @param { string } isDeleted 是否删除 0：未删除 1：已删除
 * @param { string } createdBy 创建人
 * @param { string } createdTime 创建时间
 * @param { string } updatedBy 更新人工号
 * @param { string } updatedTime 更新时间
 * @param { string } modelTable 型号数据json （用于展示
 * @param { string } isFollow 是否关注 0：未关注 1：关注
 * @param { string } numberDiscount 数量折扣
 * @param { string } levelDiscount 等级折扣
 * @param { string } lowOptimalProportion 低价最优占比
 * @param { string } lowDominantProportion 低价优势占比
 * @param { string } lowNonDisadvantageousProportion 低价非劣势占比
 */
export interface AnalysisOfPrice {
  id?: string // id
  priceNumber?: string // 分析表号
  targetCountry?: string // 目标国家
  productName?: string // 物料名称
  productCode?: string // 物料代码
  productCategory?: string // 物料类别
  modelCount?: string // 型号总数
  sampleCount?: string // 样本数
  monetaryUnit?: string // 货币单位
  intervalCount?: string //string 区间json （用于展示）
  fileId?: string // 文件id
  rate?: string // 兑率
  netProfit?: string // 净利润
  operationCost?: string // 运营成本
  logisticsCost?: string // 物流成本
  exportTariff?: string // 出口关税
  importTariff?: string // 进口关税
  maxDiscount?: string // 最大折扣
  profitAfterDiscount?: string // 折后利润
  optimalProportion?: string // 最优占比
  dominantProportion?: string // 优势占比
  nonDisadvantageousProportion?: string // 非劣势占比
  discountAfterProfit?: string //折后利润折扣
  remark?: string // 备注
  isEntry?: string // 是否录入 0：未录入 1：已录入
  isDeleted?: string // 是否删除 0：未删除 1：已删除
  createdBy?: string // 创建人
  createdTime?: string // 创建时间
  updatedBy?: string // 更新人工号
  updatedTime?: string // 更新时间
  modelTable?: string // 型号数据json （用于展示
  isFollow?: number // 是否关注 0：未关注 1：关注
  numberDiscount?: string // 数量折扣
  levelDiscount?: string // 等级折扣
  lowOptimalProportion?: string //低价最优占比
  lowDominantProportion?: string // 低价优势占比
  lowNonDisadvantageousProportion?: string // 低价非劣势占比
  intervalInfoVO?: IntervalInfoVO
  priceComputeTableVOList?: PriceComputeTable[]
}

export class AnalysisOfPriceReg extends ReqPage {
  data: AnalysisOfPrice
  constructor() {
    super()
    this.data = {}
  }
}

export interface AnalysisOfPriceRes extends ReqPage {
  data: AnalysisOfPrice[]
}

export interface UpdatePrice extends AnalysisOfPrice {
  updateStatus: string //FILE:文件修改 DETAIL:明细修改
  priceComputeTableVOList?: PriceComputeTable[]
}

/**
 * @description:计算表格数据
 * @param { string } rate 兑率
 * @param { string } netProfit 净利润
 * @param { string } operationCost 运营成本
 * @param { string } logisticsCost 物流成本
 * @param { string } exportTariff 出口关税
 * @param { string } importTariff 进口关税
 * @param { string } maxDiscount 最大折扣
 * @param { string } profitAfterDiscount 折后利润
 */
export interface ComputeReg {
  rate: string //兑率
  netProfit: string //净利润
  operationCost: string //运营成本
  logisticsCost: string //物流成本
  exportTariff: string //出口关税
  importTariff: string //进口关税
  maxDiscount: string //最大折扣
  sampleCount: string
  profitAfterDiscount: string //折后利润
  priceComputeTableVOList: PriceComputeTable[]
}

/**
 * @description:计算表格数据集合
 * @param { string } yhdProductModel 怡合达型号
 * @param { string } msmProductModel 米思米型号
 * @param { string } cnYHDTaxTopPrice 中国怡合达含税最高价
 * @param { string } cnYHDTaxLowPrice 中国怡合达含税最低价
 * @param { string } cnYHDTaxCostPrice 中国怡合达含税成本
 * @param { string } cnMSMTaxTopPrice 中国米思米含税最高价
 * @param { string } cnMSMTaxLowPrice 中国米思米含税最低价
 * @param { string } msmTaxTopPrice xxx国家米思米含税最高价
 * @param { string } msmTaxLowPrice xxx国家米思米含税最低价
 * @param { string } rmbMSMTaxTopPrice xxx国家人民币米思米含税最高价
 * @param { string } rmbMSMTaxLowPrice xxx国家人民币米思米含税最低价
 * @param { string } topDiscount 最大折扣
 * @param { string } yhdTAXTopPrice xxx国家怡合达含税最高价
 * @param { string } yhdTaxLowPrice xxx国家怡合达含税最低价
 * @param { string } customerTopCostPrice 最高价客户等同成本
 * @param { string } customerLowCostPrice 最低价客户等同成本
 * @param { string } yhd2MSMTopPriceRate xxx国家 怡合达与米思米最高价比值
 * @param { string } yhd2MSMLowPriceRate xxx国家 怡合达与米思米最低价比值
 * @param { string } cnYHD2MSMTopPriceRate 中国 怡合达与米思米最高价比值
 * @param { string } cnYHD2MSMLowPriceRate 中国 怡合达与米思米最低价比值
 * @param { string } msmTopPriceRate xxx国家与中国米思米最高价比值
 * @param { string } msmLowPriceRate xxx国家与中国米思米最低价比值
 * @param { string } yhdTopPriceRate xxx国家与中国怡合达最高价比值
 * @param { string } yhdLowPriceRate xxx国家与中国怡合达最低价比值
 */
export interface PriceComputeTable {
  yhdProductModel?: string // 怡合达型号
  msmProductModel?: string // 米思米型号
  cnYHDTaxTopPrice?: string // 中国怡合达含税最高价
  cnYHDTaxLowPrice?: string // 中国怡合达含税最低价
  cnYHDTaxCostPrice?: string // 中国怡合达含税成本
  cnMSMTaxTopPrice?: string // 中国米思米含税最高价
  cnMSMTaxLowPrice?: string // 中国米思米含税最低价
  msmTaxTopPrice?: string // xxx国家米思米含税最高价
  msmTaxLowPrice?: string // xxx国家米思米含税最低价
  rmbMSMTaxTopPrice?: string //xxx国家人民币米思米含税最高价
  rmbMSMTaxLowPrice?: string // xxx国家人民币米思米含税最低价
  topDiscount?: string // 最大折扣
  yhdTAXTopPrice?: string // xxx国家怡合达含税最高价
  yhdTaxLowPrice?: string // xxx国家怡合达含税最低价
  customerTopCostPrice?: string // 最高价客户等同成本
  customerLowCostPrice?: string // 最低价客户等同成本
  yhd2MSMTopPriceRate?: string // xxx国家 怡合达与米思米最高价比值
  yhd2MSMLowPriceRate?: string // xxx国家 怡合达与米思米最低价比值
  cnYHD2MSMTopPriceRate?: string // 中国 怡合达与米思米最高价比值
  cnYHD2MSMLowPriceRate?: string // 中国 怡合达与米思米最低价比值
  msmTopPriceRate?: string // xxx国家与中国米思米最高价比值
  msmLowPriceRate?: string // xxx国家与中国米思米最低价比值
  yhdTopPriceRate?: string //xxx国家与中国怡合达最高价比值
  yhdLowPriceRate?: string // xxx国家与中国怡合达最低价比值
}
//
/**
 * @description:生成价格分析数据
 * @param { string } targetCountry 目标国家
 * @param { string } productName 物料名称
 * @param { string } productCode 物料代码
 * @param { string } productCategory 物料类别
 * @param { string } monetaryUnit 货币单位
 */
export interface AddReg {
  targetCountry: string //目标国家
  productName: string //物料名称
  productCode: string //物料代码
  productCategory: string //物料类别
  monetaryUnit: string //货币单位
}

/**
 * @description:替换一条抽取数据
 * @param { string } id
 * @param { PriceComputeTable[] } priceComputeTableVOList 计算表格数据集合 （用于计算）
 */
export interface ReplaceReg {
  id: string
  priceComputeTableVOList: PriceComputeTable[]
}

export interface CatalogRes {
  catalogInformationList: CatalogInformation[]
}

export interface CatalogInformation {
  productCode?: string //代码
  productName?: string //产品名称
  productCategory?: string //产品类别
}

/**
 * @description:区间数据
 * @param { string } interval1 最高价0-0.4
 * @param { string } interval2 最高价0.4-0.6
 * @param { string } interval3 最高价0.6-0.8
 * @param { string } interval4 最高价0.8-1.0
 * @param { string } interval5 最高价1.0-1.2
 * @param { string } interval6 最高价1.2~
 * @param { string } interval7 最低价0-0.4
 * @param { string } interval8 最低价0.4-0.6
 * @param { string } interval9 最低价0.6-0.8
 * @param { string } interval10 最低价0.8-1.0
 * @param { string } interval11 最低价1.0-1.2
 * @param { string } interval12 最低价1.2~
 */
export interface IntervalInfoVO {
  interval1?: string // 最高价0-0.4
  interval2?: string // 最高价0.4-0.6
  interval3?: string // 最高价0.6-0.8
  interval4?: string // 最高价0.8-1.0
  interval5?: string // 最高价1.0-1.2
  interval6?: string // 最高价1.2~
  interval7?: string // 最低价0-0.4
  interval8?: string // 最低价0.4-0.6
  interval9?: string // 最低价0.6-0.8
  interval10?: string // 最低价0.8-1.0
  interval11?: string // 最低价1.0-1.2
  interval12?: string // 最低价1.2~
}
