
import { defineComponent, toRefs, ref, computed, reactive, Ref, nextTick } from 'vue'
import listLayout from '@/components/listLayout/listLayout.vue'
import editButton from '@/components/editButton/index.vue'
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { FillClass } from '@/model/competitiveAnalysis/fillInformation/fillInformation'
import { message } from 'ant-design-vue'
import productApi from '@/axios/api/product'
import { useStore } from 'vuex'
import Data from '@/model/common/Data'
import yTable from '@/components/yTable/index.vue'
import FormItem from '@/components/formItem/index.vue'
import { FormItemListType } from '@/types/comm'
import modalForm from '@/components/modalForm/index.vue'
import FileItem from '@/model/common/fileItem'
import {
  AnalysisOfPrice,
  AnalysisOfPriceReg,
  CatalogInformation,
  AddReg,
} from '@/model/competitiveAnalysis/analysisOfPrice/analysisOfPrice'
import analysisOfPriceApi from '@/axios/api/analysisOfPrice'
import { debounce } from 'lodash-es'
import { deleteModal } from '@/utils/deleteModal'
import Drawer from '@/components/Drawer/Drawer.vue'
import { ColumnsTypeData } from '@/components/Drawer/Drawer'
export default defineComponent({
  name: 'peerData',
  components: {
    listLayout,
    editButton,
    yTable,
    FormItem,
    modalForm,
    Drawer,
  },
  setup() {
    onBeforeRouteUpdate((to, from) => {
      console.log(from.name, pageData.selectFrom)
      store.commit('setRegForm', [from.name, pageData.selectFrom])
    })
    /**
     * @description: 获取筛选后可展示表头列表
     * @param { Array<ColumnsTypeData> } data 当前所有表头列表
     * @return {*}
     */
    const updateColumns = (value: Array<ColumnsTypeData>) => {
      pageData.loading = true
      pageData.columns = value
      nextTick(() => {
        pageData.loading = false
      })
    }
    const peerTable = ref()
    //获取路由
    let router = useRouter()
    let route = useRoute()
    //获取store
    const store = useStore()
    const buttonObj = store.getters.getButtonObj
    const pageData = reactive({
      isShowDrawer: false, //列设置弹框展示控制
      selectFrom: new AnalysisOfPriceReg(),
      dataSource: [] as AnalysisOfPrice[],
      columns: [
        {
          title: '分析报表编号',
          dataIndex: 'priceNumber',
          width: 200,
          key: 'priceNumber',
          resizable: true,
        },
        {
          title: '目标国家',
          dataIndex: 'targetCountry',
          width: 130,
          key: 'targetCountry',
          resizable: true,
        },
        {
          title: '物料名称',
          dataIndex: 'productName',
          width: 180,
          key: 'productName',
          resizable: true,
        },
        {
          title: '物料代码',
          dataIndex: 'productCode',
          width: 150,
          key: 'productCode',
          resizable: true,
        },
        {
          title: '物料类别',
          dataIndex: 'productCategory',
          width: 95,
          key: 'productCategory',
          resizable: true,
        },
        {
          title: '型号总数',
          dataIndex: 'modelCount',
          width: 95,
          key: 'modelCount',
          resizable: true,
        },
        {
          title: '样本数',
          dataIndex: 'sampleCount',
          width: 95,
          key: 'sampleCount',
          resizable: true,
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 95,
          key: 'remark',
          ellipsis: true,
          resizable: true,
        },
        {
          title: '创建人',
          dataIndex: 'createdBy',
          width: 95,
          key: 'createdBy',
          resizable: true,
        },

        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          width: 200,
          fixed: 'right',
          resizable: true,
        },
      ] as ColumnsTypeData[],
      productCodeList: [] as Data[],
      selectedRowKeys: [] as string[],
      loading: false,
      fetching: false,
      formItemList: [
        {
          type: 'subInput',
          prop: 'priceNumber',
          placeholder: '请输入分析报表编号',
          label: '分析报表编号',
        },
        {
          type: 'subInput',
          prop: 'createdBy',
          placeholder: '请输入创建人',
          label: '创建人',
        },
        {
          type: 'subInput',
          prop: 'productCategory',
          placeholder: '请输入物料类别 ',
          label: '物料类别 ',
        },
        {
          type: 'subInput',
          prop: 'targetCountry',
          placeholder: '请输入目标国家',
          label: '目标国家',
        },
        // {
        //   type: 'slot',
        //   prop: 'deptCode',
        //   placeholder: '请选择所属部门',
        //   label: '所属部门:',
        // },
        {
          type: 'subSelect',
          prop: 'isEntry',
          placeholder: '请选择是否录入',
          label: '是否录入:',
          optionList: [
            { label: '已录入', value: 1 },
            { label: '未录入', value: 0 },
          ],
        },
        {
          type: 'subSelect',
          prop: 'isFollow',
          placeholder: '请选择是否关注',
          label: '是否关注:',
          optionList: [
            { label: '关注', value: 1 },
            { label: '未关注', value: 0 },
          ],
        },
      ] as FormItemListType<AnalysisOfPrice>,
      reportStatus: {
        0: '未填报',
        1: '部分填报',
        2: '全部填报',
      } as Data,
      optionList: [] as Data[],
      modalData: {
        title: '新增',
        form: {} as AddReg,
        formItemList: [
          {
            type: 'subInput',
            prop: 'targetCountry',
            placeholder: '请选择目标国家',
            label: '目标国家',
          },
          {
            type: 'subInput',
            prop: 'monetaryUnit',
            placeholder: '请选择货币单位',
            label: '货币单位',
          },
          {
            type: 'slot',
            prop: 'productCode',
            placeholder: '请选择产品代码',
            label: '产品代码',
            optionList: [],
          },
          {
            type: 'subInput',
            prop: 'productName',
            placeholder: '请选择物料名称',
            label: '物料名称',
            readonly: true,
          },
          {
            type: 'subInput',
            prop: 'productCategory',
            placeholder: '请选择物料类别',
            label: '物料类别',
            readonly: true,
          },
        ] as FormItemListType<Data>,
        rules: {
          targetCountry: [{ required: true, message: '目标国家不可为空', trigger: 'blur' }],
          monetaryUnit: [{ required: true, message: '货币单位不可为空', trigger: 'blur' }],
          productCode: [{ required: true, message: '物料代码不可为空', trigger: 'change' }],
          productName: [{ required: true, message: '物料名称不可为空', trigger: 'change' }],
          productCategory: [{ required: true, message: '物料类别不可为空', trigger: 'change' }],
        },
        visible: false,
        labelCol: { style: { width: '100px' } },
        width: '25%',
        ok: (formRef?: Ref) => {
          formRef?.value
            .validate()
            .then(() => {
              analysisOfPriceApi
                .analysisOfPriceApiAdd(pageData.modalData.form)
                .then((res) => {
                  // pageData.modalData.form = {} as AddReg
                  // pageData.modalData.visible = false
                  // formRef.value.resetFields()
                  // selectProduct()
                  router.push(`/competitiveAnalysis/analysisOfPrice/analysisOfPriceDetail/${res}`)
                  message.success('成功')
                })
                .catch((err) => {
                  message.error(err)
                })
            })
            .catch(() => {
              message.warning('所选产品代码的物料信息为空或信息不全')
            })
        },
      },
      addRemarkVisible: false,
      remarkForm: {
        remark: '',
        id: '',
        updateStatus: 'DETAIL',
      },
    })
    if (store.state.regForm[route.name as string] !== undefined) {
      pageData.selectFrom = JSON.parse(JSON.stringify(store.state.regForm[route.name as string]))
    }
    console.log(pageData.selectFrom)
    //获取表格信息
    const initeList = () => {
      pageData.loading = true
      analysisOfPriceApi
        .analysisOfPriceApiList(pageData.selectFrom)
        .then((res) => {
          pageData.dataSource = []
          if (res.data) {
            Object.assign(pageData.dataSource, res.data)
          }
          pageData.selectFrom.currentPage = res.currentPage as number
          pageData.selectFrom.pageSize = res.pageSize
          pageData.selectFrom.dataCount = res.dataCount
          pageData.loading = false
        })
        .catch((err) => {
          pageData.loading = false
          message.error(err)
        })
    }
    //重置数据
    const selectProduct = () => {
      pageData.selectFrom.currentPage = 1
      pageData.selectFrom.pageSize = 10
      initeList()
    }
    //查询数据
    selectProduct()
    //传入table的页面配置
    const currentConfigure = computed(() => {
      let page = {
        pageSize: pageData.selectFrom.pageSize,
        currentPage: pageData.selectFrom.currentPage,
        total: pageData.selectFrom.dataCount,
        onChange(page: number, pageSize: number) {
          pageData.selectFrom.pageSize = pageSize
          pageData.selectFrom.currentPage = page
          initeList()
        },
        onShowSizeChange(current: number, size: number) {
          pageData.selectFrom.pageSize = size
          pageData.selectFrom.currentPage = current
          initeList()
        },
      }
      return page
    })
    //跳转至账号设置页面
    const jump = (type: string, id: string, reportStatus?: number) => {
      if (type === 'add') {
        if (reportStatus === 2) {
          message.info('该数据已经填报完成,无需填报。')
          return
        }
        router.push(`/competitiveAnalysis/fillInformation/addFill/${id}`)
      } else if (type === 'update') {
        if (reportStatus === 0) {
          message.info('该数据未填报!')
          return
        }
        router.push(`/competitiveAnalysis/fillInformation/updateFill/${id}`)
      } else if (type === 'detail') {
        if (reportStatus === 0) {
          message.info('该数据未填报!')
          return
        }
        router.push(`/competitiveAnalysis/fillInformation/Detail/${id}`)
      }
    }
    //新增编辑按钮
    const addUpdateFill = () => {
      if (pageData.selectedRowKeys.length !== 1) {
        message.warning('请选择一项数据！')
        return
      }
      // let data = pageData.dataSource.find(
      //   (item) => item.productCode === pageData.selectedRowKeys[0]
      // )
      // console.log(data?.reportStatus)

      // jump(type, pageData.selectedRowKeys[0], data?.reportStatus as number)
    }

    //搜索
    const search = () => {
      selectProduct()
    }
    //获取产品部门选项
    productApi
      .GetProductDept()
      .then((res) => {
        pageData.optionList = res.map((item) => {
          return {
            label: item.deptName,
            value: item.deptCode,
          }
        })
      })
      .catch((err) => {
        message.error(err)
      })
    //重置
    const reset = () => {
      pageData.selectFrom.data = new FillClass()
      selectProduct()
    }

    // 全选事件
    const checkedChange = (checked: boolean) => {
      pageData.selectedRowKeys = checked
        ? (pageData.dataSource.map((item) => item.productCode) as string[]) || []
        : []
    }

    // 远程搜索型号
    const fetchProductCode = debounce((value: string) => {
      console.log('fetching user', value)
      pageData.fetching = true
      analysisOfPriceApi
        .analysisOfPriceApiCatalogList({
          productCode: value,
          noLoading: true,
        })
        .then((res) => {
          pageData.productCodeList =
            res.catalogInformationList?.map((item) => ({
              label: item.productCode,
              value: item.productCode,
              productName: item.productName,
              productCategory: item.productCategory,
            })) || []
        })
        .catch((err) => {
          message.error(err)
        })
        .finally(() => {
          pageData.fetching = false
        })
    }, 300)

    // 选择型号改变事件
    const productCodeChange = (_value: string, option: CatalogInformation) => {
      pageData.modalData.form.productName = option.productName as string
      pageData.modalData.form.productCategory = option.productCategory as string
    }

    // 设置表单数据
    const onUpdateForm = (val: string, prop: keyof AddReg) => {
      pageData.modalData.form[prop] = val
    }

    // 删除
    const deleteList = () => {
      const ok = () => {
        analysisOfPriceApi
          .analysisOfPriceApiDelete(pageData.selectedRowKeys)
          .then(() => {
            message.success('删除成功')
            pageData.selectedRowKeys = []
            initeList()
          })
          .catch((err) => {
            message.error(err)
          })
      }
      const onCancel = () => {
        console.log('Cancel')
      }
      deleteModal(ok, onCancel)
    }

    // 导入函数
    const importFile = (param: FileItem) => {
      const formData = new FormData()
      formData.append('file', param.file as Blob)
      analysisOfPriceApi
        .analysisOfPriceApiImport(formData)
        .then(() => {
          message.success('导入成功')
          initeList()
        })
        .catch((err) => {
          message.error(err)
        })
    }

    // 批量导出
    const exportFile = (id?: string) => {
      analysisOfPriceApi
        .analysisOfPriceApiExport(id ? [id] : pageData.selectedRowKeys)
        .then((res) => {
          console.log(res)
          let blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' })
          let url = window.URL.createObjectURL(blob)
          let a = document.createElement('a')
          a.download = ''
          a.href = url
          a.click()
          a.remove()
          message.success('导出成功')
        })
        .catch((err) => {
          message.error(err)
        })
    }

    const addRemark = () => {
      analysisOfPriceApi
        .analysisOfPriceApiUpdate(pageData.remarkForm)
        .then(() => {
          message.success('备注成功')
          pageData.remarkForm.id = ''
          pageData.remarkForm.remark = ''
          pageData.addRemarkVisible = false
          initeList()
        })
        .catch((err) => {
          message.error(err)
        })
    }

    const follow = (data: AnalysisOfPrice) => {
      if (data.isFollow === 1) {
        analysisOfPriceApi
          .analysisOfPriceApiFollowCancel(data.id as string)
          .then(() => {
            message.success('取消关注成功')
            data.isFollow = 0
          })
          .catch((err) => {
            message.error(err)
          })
      } else {
        analysisOfPriceApi
          .analysisOfPriceApiFollowAdd(data.id as string)
          .then(() => {
            message.success('关注成功')
            data.isFollow = 1
          })
          .catch((err) => {
            message.error(err)
          })
      }
    }

    const openRemark = (data: AnalysisOfPrice) => {
      pageData.remarkForm.id = data.id as string
      pageData.remarkForm.remark = data.remark as string
      pageData.addRemarkVisible = true
    }

    return {
      openRemark,
      follow,
      addRemark,
      exportFile,
      importFile,
      deleteList,
      onUpdateForm,
      productCodeChange,
      fetchProductCode,
      addUpdateFill,
      ...toRefs(pageData),
      peerTable,
      search,
      jump,
      reset,
      buttonObj,
      currentConfigure,
      selectProduct,
      checkedChange,
      updateColumns,
    }
  },
})
