import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f338f6a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: { color: '#13c2c2' }
}
const _hoisted_2 = { key: 1 }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { style: { color: '#13c2c2', padding: '0 3px' } }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_editButton = _resolveComponent("editButton")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_yTable = _resolveComponent("yTable")!
  const _component_Drawer = _resolveComponent("Drawer")!
  const _component_listLayout = _resolveComponent("listLayout")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_modalForm = _resolveComponent("modalForm")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_listLayout, {
      num: _ctx.selectedRowKeys.length,
      onEmpty: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectedRowKeys.length = 0))
    }, {
      selectList: _withCtx(() => [
        _createVNode(_component_a_form, {
          layout: "inline",
          model: _ctx.selectFrom.data,
          "label-col": { style: { width: '110px' } }
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formItemList, (item) => {
              return (_openBlock(), _createBlock(_component_a_form_item, {
                key: item.prop,
                label: item.label,
                name: item.prop
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    value: _ctx.selectFrom.data[item.prop],
                    "onUpdate:value": ($event: any) => ((_ctx.selectFrom.data[item.prop]) = $event),
                    formItemContent: item
                  }, {
                    deptCode: _withCtx(() => [
                      _createVNode(_component_a_select, {
                        value: _ctx.selectFrom.data[item.prop],
                        "onUpdate:value": ($event: any) => ((_ctx.selectFrom.data[item.prop]) = $event),
                        placeholder: item.placeholder
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.optionList, (item) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: item.value,
                              value: item.value
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(item.label), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, 1032, ["value", "onUpdate:value", "placeholder"])
                    ]),
                    _: 2
                  }, 1032, ["value", "onUpdate:value", "formItemContent"])
                ]),
                _: 2
              }, 1032, ["label", "name"]))
            }), 128)),
            _createVNode(_component_a_form_item, {
              class: "button",
              label: " ",
              colon: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_button, {
                  type: "primary",
                  onClick: _ctx.selectProduct
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("查询")
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_a_button, { onClick: _ctx.reset }, {
                  default: _withCtx(() => [
                    _createTextVNode("重置")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      buttonList: _withCtx(() => [
        _createVNode(_component_editButton, {
          icon: _ctx.buttonObj['price-add'],
          text: "新增",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalData.visible = true)),
          type: "primary"
        }, null, 8, ["icon"]),
        _createElementVNode("span", {
          class: "iconfont icon-lieshezhi pointer",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowDrawer = true))
        })
      ]),
      table: _withCtx(() => [
        _createVNode(_component_yTable, {
          dataSource: _ctx.dataSource,
          columns: _ctx.columns,
          selectedRowKeys: _ctx.selectedRowKeys,
          "onUpdate:selectedRowKeys": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedRowKeys) = $event)),
          currentConfigure: _ctx.currentConfigure,
          rowKey: 'id'
        }, {
          bodyCell: _withCtx(({ column, record }) => [
            (column.dataIndex === 'shorthand')
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(record.shorthand), 1))
              : (column.dataIndex === 'operation')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    (_ctx.buttonObj['price-follow-add'])
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 0,
                          class: "edit",
                          style: _normalizeStyle({
                    color: record.isFollow === 1 ? '#f96868' : '#13c2c2',
                    cursor: 'pointer',
                  }),
                          onClick: ($event: any) => (_ctx.follow(record))
                        }, _toDisplayString(record.isFollow === 1 ? '取消关注' : '关注'), 13, _hoisted_3))
                      : _createCommentVNode("", true),
                    (_ctx.buttonObj['price-update'])
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: "edit",
                          onClick: ($event: any) => (
                    _ctx.$router.push(
                      `/competitiveAnalysis/analysisOfPrice/analysisOfPriceDetail/${record.id}`
                    )
                  )
                        }, "查看详情", 8, _hoisted_4))
                      : _createCommentVNode("", true),
                    (_ctx.buttonObj['price-update'])
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 2,
                          class: "edit",
                          onClick: ($event: any) => (_ctx.openRemark(record))
                        }, "备注", 8, _hoisted_5))
                      : _createCommentVNode("", true),
                    (_ctx.buttonObj['price-export'])
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 3,
                          class: "edit",
                          onClick: ($event: any) => (_ctx.exportFile(record.id))
                        }, "下载", 8, _hoisted_6))
                      : _createCommentVNode("", true)
                  ]))
                : (column.dataIndex === 'isFollow')
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      _createElementVNode("i", {
                        class: "iconfont icon-xinhao",
                        style: _normalizeStyle({
                  'font-size': '10px',
                  color: record.isFollow === 1 ? '#13c2c2' : '#f96868',
                  'margin-right': '5px',
                })
                      }, null, 4),
                      _createTextVNode(" " + _toDisplayString(record.isFollow === 1 ? '已关注' : '未关注'), 1)
                    ], 64))
                  : _createCommentVNode("", true)
          ]),
          footerLeft: _withCtx(() => [
            _createVNode(_component_a_checkbox, {
              checked: _ctx.dataSource.length === _ctx.selectedRowKeys.length && _ctx.dataSource.length !== 0,
              style: { 'margin-left': '20px' },
              "onUpdate:checked": _ctx.checkedChange
            }, {
              default: _withCtx(() => [
                _createTextVNode("已选择"),
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.selectedRowKeys.length), 1),
                _createTextVNode("项")
              ]),
              _: 1
            }, 8, ["checked", "onUpdate:checked"]),
            _createElementVNode("span", {
              style: { color: '#13c2c2', cursor: 'pointer' },
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectedRowKeys.length = 0))
            }, "清空已选择"),
            (_ctx.buttonObj['price-import'])
              ? (_openBlock(), _createBlock(_component_a_upload, {
                  key: 0,
                  "custom-request": _ctx.importFile,
                  showUploadList: false
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_button, { style: { 'margin-left': '72px', 'margin-right': '8px' } }, {
                      default: _withCtx(() => [
                        _createTextVNode("批量导入")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["custom-request"]))
              : _createCommentVNode("", true),
            (_ctx.buttonObj['price-export'])
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 1,
                  style: { 'margin-right': '8px' },
                  disabled: _ctx.selectedRowKeys.length === 0,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.exportFile()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("批量下载")
                  ]),
                  _: 1
                }, 8, ["disabled"]))
              : _createCommentVNode("", true),
            (_ctx.buttonObj['price-delete'])
              ? (_openBlock(), _createBlock(_component_a_button, {
                  key: 2,
                  style: { 'margin-right': '8px' },
                  disabled: _ctx.selectedRowKeys.length === 0,
                  onClick: _ctx.deleteList
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("批量删除")
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["dataSource", "columns", "selectedRowKeys", "currentConfigure"]),
        _createVNode(_component_Drawer, {
          visible: _ctx.isShowDrawer,
          "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isShowDrawer) = $event)),
          "origin-columns": _ctx.columns,
          "page-name": "analysisOfPrice",
          onUpdateColumns: _ctx.updateColumns
        }, null, 8, ["visible", "origin-columns", "onUpdateColumns"])
      ]),
      _: 1
    }, 8, ["num"]),
    _createVNode(_component_modalForm, {
      modalData: _ctx.modalData,
      visible: _ctx.modalData.visible,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.modalData.visible) = $event)),
      onOnUpdateForm: _ctx.onUpdateForm
    }, {
      productCode: _withCtx(() => [
        _createVNode(_component_a_select, {
          value: _ctx.modalData.form.productCode,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.modalData.form.productCode) = $event)),
          placeholder: "请输入编码查询",
          style: {"width":"100%"},
          showSearch: "",
          "filter-option": false,
          "not-found-content": _ctx.fetching ? undefined : null,
          options: _ctx.productCodeList,
          onSearch: _ctx.fetchProductCode,
          onChange: _ctx.productCodeChange
        }, _createSlots({ _: 2 }, [
          (_ctx.fetching)
            ? {
                name: "notFoundContent",
                fn: _withCtx(() => [
                  _createVNode(_component_a_spin, { size: "small" })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["value", "not-found-content", "options", "onSearch", "onChange"])
      ]),
      _: 1
    }, 8, ["modalData", "visible", "onOnUpdateForm"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.addRemarkVisible,
      "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.addRemarkVisible) = $event)),
      title: "备注",
      onOk: _ctx.addRemark
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form, {
          model: _ctx.remarkForm,
          name: "basic",
          "label-col": { span: 4 },
          "wrapper-col": { span: 18 }
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_form_item, {
              label: "备注",
              name: "remark"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_textarea, {
                  value: _ctx.remarkForm.remark,
                  "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.remarkForm.remark) = $event)),
                  "auto-size": { minRows: 3, maxRows: 5 },
                  "show-count": "",
                  maxlength: 200
                }, null, 8, ["value"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model"])
      ]),
      _: 1
    }, 8, ["visible", "onOk"])
  ]))
}